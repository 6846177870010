import * as React from 'react';
import { Disclosure } from '@headlessui/react';
import { ServicesMenu } from '../services-menu';
import { Service } from '../utils/service';
import { ChevronRightIcon } from '@heroicons/react/outline';

export function Skin() {
  return (
    <>
      <ServicesMenu service="Skin" />
      <div className="px-4">
        <br />
        <p>
          At Port Day Spa we believe that your skin deserves the very best.
          That's why we've chosen Ultraceuticals, a brand that shares our
          passion for achieving remarkable results. With a legacy of innovation,
          scientific research, and cutting-edge formulations, Ultraceuticals
          aligns perfectly with our goal of providing you with top-tier skincare
          solutions.
          <br />
          <br />
          Our commitment to excellence doesn't stop there – we've invested in
          state-of-the-art technology to ensure your skin experiences the
          pinnacle of care. Our cutting-edge machinery includes Dermalux LED,
          Exceed Medical Microneedling, Ultraceuticals SonoBooster™ technology
          and Diamond Microdermabrasion.
          <br />
          <br />
          We’ve meticulously crafted our skin menu into three distinct
          categories: Condition, Correct, and Relax. Each category reflects our
          unwavering commitment to your skin's unique needs and aspirations.
          <br />
          <br />
          But what truly sets us apart is our unwavering commitment to merging
          results with relaxation. Every treatment we offer is a harmonious
          blend of effective solutions and pampering indulgence. You'll walk out
          not only with skin that looks and feels revitalized but also with a
          sense of deep calm and renewal.
          <br />
          <br />
          Behind every treatment is a team of fully trained and passionate
          therapists. Their expertise, combined with their genuine dedication to
          your well-being, ensures that you're in the best hands possible. We
          take pride in being your partners on your skincare journey, tailoring
          each experience to cater to your unique skin and preferences.
          <br />
          <br />
          Indulge in the luxury of science-meets-beauty, where results and
          relaxation entwine seamlessly. Welcome to our world of premium
          skincare – a place where your skin's health and your relaxation are
          our top priorities.
        </p>
        <br />
        <h2 className="font-serif text-2xl sm:text-3xl">CONDITION</h2>
        <p>Targeted facial solutions to replenish, strengthen and correct.</p>
        <Service title="Skin Discovery" price="$89" time="60 mins">
          <p>
            Your skin journey begins here. Using our state-of-the-art skin
            scanner designed to look at the deeper layers of the skin, your skin
            expert will conduct an in-depth consultation to truly understand
            your skin. We will then create a personalised prescription and
            tailor make a bespoke treatment journey.
          </p>
        </Service>
        <Service title="LED Light Therapy" price="$85" time="40 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse, preparing your
                    skin for the luminous journey ahead. Our gentle exfoliation
                    process unveils a revitalized canvas, setting the stage for
                    the transformative LED therapy that follows. As you drift
                    into a state of pure bliss, enjoy a pampering hand and arm
                    massage that melts away stress. The experience concludes
                    with carefully selected finishing products, enveloping your
                    skin in a protective embrace.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            Dermalux LED Phototherapy is a non-invasive treatment that uses
            clinically proven therapeutic light energy to trigger the skins
            natural rejuvenation and repair processes without pain or downtime.
            Effective for all skin types and conditions.
          </p>
          <p>LED Light Therapy Addons:</p>
          <p>
            $52 added to any facial
            <br />
            $850 course of 12 sessions
          </p>
        </Service>
        <Service title="Renew" price="$115" time="30 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a double cleanse, microdermabrasion to
                    gently exfoliate, revealing a radiant and refreshed
                    complexion. A bespoke mask is then applied, infusing your
                    skin with nourishing ingredients and enhancing its natural
                    vitality. As you drift into serenity, a soothing scalp
                    massage sweeps you into a world of relaxation. The final
                    touch involves the application of protective finishing
                    products, leaving your skin not only rejuvenated but also
                    shielded.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            This introductory microdermabrasion facial will leave your skin
            smooth and luminous. Revitalise a dull tired skin or treat that
            pesky congestion.
          </p>
        </Service>
        <Service title="Skin Workout" price="$122" time="45 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse involving our
                    SonoBooster™ technology, effectively removing impurities and
                    preparing your skin for the transformation ahead. Tailored
                    to your unique skin needs, a customised peel follows, gently
                    exfoliating and revitalizing your complexion, while
                    promoting a smoother texture. As you drift into relaxation,
                    our performance mask works its magic, infusing your skin
                    with potent nutrients and moisture, leaving it supple and
                    refreshed. A soothing scalp massage, to melt away tension
                    and enhancing circulation. Finally, we seal in the goodness
                    with our carefully selected protective products, ensuring
                    your skin remains nourished and shielded. <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            A rejuvenating skin peel customised to treat your skin concerns,
            from dullness & dehydration to congestion & breakouts.
          </p>
        </Service>
        <Service title="Radiance" price="$132" time="45 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a meticulous double cleanse, paving the
                    way for a pre-peel prep that readies your complexion for
                    transformation. Ultraceuticals Brightening Accelerator mask
                    features a powerful blend of Alpha Hydroxy Acid (AHAs),
                    Salicylic Acid (BHA), Niacinamide (Vitamin B3) and
                    brightening agents that help to resurface the skin, restore
                    skin clarity and luminosity. SonoBooster™ technology and
                    targeted corrective serums follow, revitalizing your skin's
                    texture and tone. A soothing performance mask sweeps you
                    into tranquility, as a relaxing scalp massage releases
                    tension. Finally, protective finishing products seal in the
                    newfound glow, leaving you with skin that radiates youthful
                    luminosity and renewed vitality.
                    <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            A skin brightening treatment to rapidly reduce hyperpigmentation and
            blotchiness instantly revealing a luminous complexion.
          </p>
        </Service>
        <br />
        <br />
        <h2 className="font-serif text-2xl sm:text-3xl">CORRECT</h2>
        <p>
          A collection of powerhouse paramedical facial solutions to deliver
          real visible skin results.
        </p>
        <Service title="LED PLUS+" price="$189" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a refreshing double cleanse, sweeping
                    away impurities and setting the stage for a radiant
                    transformation. Our microdermabrasion technique gently
                    exfoliates, revealing a smoother and more youthful
                    complexion. The soothing Dermalux LED therapy follows,
                    bathing your skin in rejuvenating light that targets
                    specific concerns, leaving you with a revitalized glow.
                    Indulge further as our skilled therapists treat your hands
                    and arms to a relaxing massage, melting away stress and
                    promoting relaxation. The Ultraceuticals B2 Hydration Mask
                    deeply nourishes and restores your skin's moisture balance,
                    providing a surge of hydration for a plump, radiant look.
                    Your journey culminates with a calming scalp massage,
                    ensuring every inch of you is enveloped in tranquility. To
                    lock in the benefits, we carefully apply protective
                    products, ensuring your renewed radiance is shielded and
                    maintained.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            This luxurious treatment combines the exfoliating power of
            microdermabrasion with the revitalising effects of LED light therapy
            and a hydrating sheet mask for the ultimate skin rejuvenation
            experience.
          </p>
        </Service>
        <Service title="Refine & Hydrate" price="$195" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse that sweeps
                    away impurities, paving the way for the advanced
                    SonoBooster™ technology to infuse your skin with
                    revitalizing nutrients. Our Ultraceuticals PHA refining peel
                    gently exfoliates, unveiling a smoother texture and radiant
                    glow. Relax and unwind as a performance mask nourishes your
                    skin, while a soothing scalp massage melts away stress.
                    Finally, bask in the protective embrace of our finishing
                    products, leaving your skin beautifully transformed,
                    revitalised, and ready to face the world with a newfound
                    radiance.
                    <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            This gentle facial peel treatment refines the surface of the skin
            leaving it looking plumped, rejuvenated, brighter and glowing, with
            minimal downtime. This treatment also incorporates our SonoBooster™
            technology. Suitable for all skin types including sensitive.
          </p>
        </Service>
        <Service title="Hydrate & Glow" price="$195" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse, purging
                    impurities and preparing for the transformation ahead.
                    Elevating the experience, our cutting-edge SonoBooster™
                    technology ensures that every subsequent step is deeply
                    absorbed, optimizing results. Tailored precisely to your
                    skin's unique needs, a customised Lactic peel unveils a
                    fresh canvas, while the Ultraceuticals Brightening
                    Accelerator Mask ignites a radiant glow, targeting uneven
                    tones for a luminous complexion. Precision meets luxury with
                    a corrective serum infusion, addressing specific concerns
                    and promoting lasting skin vitality. A lavish performance
                    mask follows, cocooning your skin in opulent nourishment,
                    leaving it visibly revitalized and youthful. Drift into
                    relaxation as a soothing scalp massage melts away stress,
                    harmonising the experience. The journey culminates with the
                    application of protective products, sealing in the benefits
                    and safeguarding your radiant transformation.
                    <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            Instantly plump and hydrate the skin with this lactic peel and
            brightening mask infusion. This treatment also incorporates our
            SonoBooster™ technology. Perfect for dry, dehydrated, dull skin.
          </p>
        </Service>
        <Service title="Clear & Glow" price="$195" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse, purging
                    impurities and preparing for the transformation ahead.
                    Elevating the experience, our cutting-edge SonoBooster™
                    technology ensures that every subsequent step is deeply
                    absorbed, optimizing results. Tailored precisely to your
                    skin's unique needs, a customised Mandelic peel unveils a
                    fresh canvas, while the Ultraceuticals Brightening
                    Accelerator Mask ignites a radiant glow, targeting uneven
                    tones for a luminous complexion. Precision meets luxury with
                    a corrective serum infusion, addressing specific concerns
                    and promoting lasting skin vitality. A lavish performance
                    mask follows, cocooning your skin in opulent nourishment,
                    leaving it visibly revitalized and youthful. Drift into
                    relaxation as a soothing scalp massage melts away stress,
                    harmonising the experience. The journey culminates with the
                    application of protective products, sealing in the benefits
                    and safeguarding your radiant transformation.
                    <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            Targeting excess oil and cell build up to clarify and deep cleanse.
            This skin workout also combines our radiance boosting mask with the
            power of our SonoBooster™ technology. Perfect for skin struggling
            with congestion, breakouts and acne.
          </p>
        </Service>
        <Service title="Rejuvenation" price="$207" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a thorough double cleanse ensures a clean
                    canvas for the transformative process ahead. Experience the
                    power of the renowned Ultraceuticals Azyme peel, expertly
                    applied to reveal rejuvenated skin. Harnessing the
                    cutting-edge SonoBooster™ technology, your skin receives
                    targeted revitalization through serum infusions that address
                    your unique concerns. A performance mask provides an extra
                    layer of nourishment, while a soothing scalp massage
                    enhances the overall relaxation. As the treatment concludes,
                    protective finishing products are delicately applied,
                    leaving you with a renewed, radiant complexion that embodies
                    both beauty and confidence.
                    <br />
                    <br />
                    EXPERT TIP: To achieve the best results, book in for a
                    series of 3 treatments with a minimum of 2 weeks between
                    each.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            Our powerhouse A-Zyme skin peel. This fast-acting noninvasive skin
            rejuvenation treatment combines proven anti-ageing ingredients
            Retinol and Bromelain to refresh, refine, hydrate and plump skin.
            Works quickly to dramatically diminish the appearance of fine lines
            and wrinkles, even skin tone and improve overall skin luminosity.
            This treatment also incorporates our SonoBooster™ technology.
          </p>
        </Service>
        <Service title="Collagen Induction Therapy" price="$255" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment also involves an Epi Nouvelle Cellulose Mask to
                    calm and soothe.
                    <br />
                    <br />
                    TREATMENT GUIDELINES:
                    <br/>
                    Rejuvenation / 4-6 treatments / 3-4 weeks apart
                    <br />
                    Acne Scarring / 6 treatments / 4-6 weeks apart
                    <br />
                    Stretch Marks / 4-8 treatments / 4-6 weeks apart
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            Utilising the Exceed medical micro needling device for clinically
            proven results. Also known as Skin Needling, this procedure involves
            very tiny sterile needles to create controlled micro-injuries,
            increasing natural collagen production and stimulating cell
            regeneration. A safe and effective treatment fantastic for treating
            pigmentation, scarring, fine lines & wrinkles and overall skin
            texture. Dermal needling is an essential for those wanting to
            transform their skin.
          </p>
        </Service>

        <br />
        <br />
        <h2 className="font-serif text-2xl sm:text-3xl">RELAX</h2>
        <p>
          Designed to promote relaxation & reduce stress while providing
          nourishment & rejuvenation for the skin.
        </p>
        <Service title="Refresh" price="$102" time="30 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatments involves a thorough double cleanse, followed by a
                    gentle yet effective exfoliation that unveils your skin's
                    inner luminosity. A mask, artfully massaged in, infuses your
                    complexion with nourishing ingredients, leaving you with a
                    renewed and vibrant glow. As tension melts away during a
                    soothing scalp massage, you'll find yourself enveloped in
                    relaxation. The treatment concludes with the application of
                    protective products, ensuring that your refreshed skin
                    remains hydrated and safeguarded, ready to face the world
                    with a newfound vitality.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            The perfect pick me up facial. This treatment will leave your skin
            feeling revived and energised.
          </p>
        </Service>
        <Service
          title="Gua Sha Crystal Fusion Facial"
          price="$175"
          time="60 mins"
        >
          <p>
            A sensory experience, relieves muscle tension and stimulates
            lymphatic flow with this ancient Chinese healing ritual. Relax as
            the Gua Sha stones are massaged over the skin, reducing puffiness
            and fine lines. Allow the vibrational energy of our crystal mask to
            instantly calm, easing you of stress, anxiety and tired eyes. Enjoy
            a scalp massage performed using our rose quartz crystal comb, this
            creates the most amazing feeling of relaxation and happiness.
          </p>
        </Service>
        <Service title="Age Renewal" price="$185" time="60 mins">
          <Disclosure>
            {({ open }) => (
              /* Use the `open` state to conditionally change the direction of an icon. */
              <>
                <Disclosure.Button className="p-1 my-2 bg-blue-100 border border-black">
                  Treatment involves
                  <ChevronRightIcon
                    className={
                      open
                        ? 'rotate-90 transform w-5 h-5 inline-block'
                        : 'w-5 h-5 inline-block'
                    }
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {' '}
                  <p>
                    Treatment involves a double cleanse, purging impurities and
                    prepping your skin for renewal. Elevate the experience with
                    microdermabrasion, gently exfoliating for a refined texture.
                    Revolutionize your skin's potential with SonoBooster™
                    technology, amplifying the benefits of subsequent steps. A
                    Vitamin A & C infusion delivers a surge of nutrients,
                    revitalizing your complexion from within. Indulge in
                    relaxation as a soothing massage rejuvenates both your skin
                    and senses. A customised mask follows, tailored to your
                    unique needs, leaving your skin glowing and nourished. The
                    indulgence continues with a scalp massage, melting away
                    tension and enhancing your overall well-being. Finally,
                    protective products are applied, sealing in the radiance and
                    ensuring your renewed beauty lasts.
                  </p>
                  <br />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <p>
            For relaxation and results, this treatment targets the visible signs
            of aging whilst incorporating a relaxing neck and shoulder massage.
            Microdermabrasion combined with sonophoresis infusion of powerhouse
            ingredients Vitamin A, Vitamin C, AHAs & BHAs to perfect and refine
            skins appearance.
          </p>
        </Service>
        <Service title="Warm Oil Scalp Massage" price="$39" time="">
          <p>(ADD TO ANY FACIAL)</p>
          <p>
            The perfect addition to enhance your facial relaxation. Pure Fiji
            Exotic Oil is slowly drizzled through your hairline. The warmed oil
            is then massaged gently into the scalp and spread through the hair.
            This treatment restores mental clarity and leaves hair shiny and
            healthy.
          </p>
        </Service>
      </div>
    </>
  );
}
