import PropTypes from 'prop-types';
import * as React from 'react';

import { Layout, Map } from '../components';
import {
  Beauty,
  Body,
  ChristmasPackages,
  Skin,
  SpaPackages,
} from '../components/services';
import background from '../images/background.svg';

export default function ServicesTemplate({ pageContext }) {
  const SELECTED_MENU = {
    skin: <Skin />,
    body: <Body />,
    beauty: <Beauty />,
    'christmas-packages': <ChristmasPackages />,
    'spa-packages': <SpaPackages />,
  };

  return (
    <Layout>
      <div className="relative w-full max-w-6xl mx-auto">
        <div className="absolute inset-0 hidden md:block">
          <img className="translate-left-50" src={background} alt="" />
        </div>
        <div className="relative flex flex-wrap py-12">
          <div className="flex flex-wrap w-full px-4 ml-auto md:px-12 md:w-2/3">
            <div className="w-full bg-white border">
              {SELECTED_MENU[pageContext.slug]}
              <p className="my-6 text-center">
                <a
                  className="inline-block px-2 py-1 leading-none uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:text-white hover:border-gray-800"
                  href="https://phorest.com/book/salons/portmacquariedayspa"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Book now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Map />
    </Layout>
  );
}

ServicesTemplate.propTypes = {
  pageContext: PropTypes.object,
};
