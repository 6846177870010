import PropTypes from 'prop-types';
import * as React from 'react';

export function Service({ title, children, price, time }) {
  return (
    <div className="flex flex-wrap justify-between mt-2 lg:flex-nowrap">
      <div className="pr-4 mt-4">
        <h4 className="font-bold">{title}</h4>
        {children}
      </div>
      <div className="flex-shrink-0 w-32 mt-4 font-medium leading-tight lg:text-right">
        <p>{price}</p>
        {time && (
          <p className="mt-0">
            <small>{time}</small>
          </p>
        )}
      </div>
    </div>
  );
}

Service.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  price: PropTypes.any,
  time: PropTypes.string,
};
