import { Link } from 'gatsby';
import * as React from 'react';

import { ServicesMenu } from '../services-menu';

export function Beauty() {
  return (
    <>
      <ServicesMenu service="Beauty" />
      <div className="px-4">
        <h3 className="mt-6 font-bold uppercase">Waxing</h3>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Full Leg Wax</h4>
            <p>&nbsp;-&nbsp;$67</p>
          </div>
          <p className="mt-0">inc. g-string - $91</p>
          <p className="mt-0">inc. repeat brazilian - $102</p>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Lower Half Leg</h4>
            <p>&nbsp;-&nbsp;$34</p>
          </div>
          <p className="mt-0">inc. bikini - $61</p>
          <p className="mt-0">inc. g-string - $71</p>
          <p className="mt-0">inc. repeat brazilian - $82</p>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">
              Brazilian <span className="font-normal">(1st time)</span>
            </h4>
            <p>&nbsp;-&nbsp;$77</p>
          </div>
          <p className="mt-0">Repeat within 4 weeks - $72</p>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">G-string</h4>
            <p>&nbsp;-&nbsp;$57</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Bikini</h4>
            <p>&nbsp;-&nbsp;$37</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Face</h4>
            <p>&nbsp;-&nbsp;$49</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Eyebrow shape</h4>
            <p>&nbsp;-&nbsp;$32</p>
          </div>
          <p>inc. lip - $42</p>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Chin</h4>
            <p>&nbsp;-&nbsp;$28</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Lip</h4>
            <p>&nbsp;-&nbsp;$24</p>
          </div>
          <p>inc. chin - $37</p>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Half Arm</h4>
            <p>&nbsp;-&nbsp;$39</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Full Arm</h4>
            <p>&nbsp;-&nbsp;$42</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Chest</h4>
            <p>&nbsp;-&nbsp;$54</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Back</h4>
            <p>&nbsp;-&nbsp;$54</p>
          </div>
        </div>
        <h3 className="mt-6 font-bold uppercase">Tinting</h3>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Eyebrow</h4>
            <p>&nbsp;-&nbsp;$32</p>
          </div>
          <p>
            inc. brow shape - $52
            <br />
            inc. lash tint + brow shape - $62
          </p>
        </div>
        {/* <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Henna Brows</h4>
            <p>&nbsp;-&nbsp;$59</p>
          </div>
        </div> */}
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Eyelash</h4>
            <p>&nbsp;-&nbsp;$32</p>
          </div>
          <p>inc. brow tint - $52</p>
        </div>
        <h3 className="mt-6 font-bold uppercase">Hybrid Brows</h3>
        <p>
        An extra long lasting dye to colour brow hairs for up to 7 weeks and stain the skin for up to 14 days.
        </p>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Brow Dye</h4>
            <p>&nbsp;-&nbsp;$37</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Brow Dye & Brow Shape</h4>
            <p>&nbsp;-&nbsp;$62</p>
          </div>
        </div>
        <h3 className="mt-6 font-bold uppercase">Spray tan</h3>
        <p>Your choice of colour</p>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Full body</h4>
            <p>&nbsp;-&nbsp;$39</p>
          </div>
          <p>inc. exfoliation - $92</p>
          {/* <p className="mt-0">inc. full course of 6 visits - $180</p> */}
        </div>
        
        {/* <h3 className="mt-6 font-bold uppercase">Make up</h3>
        <p>
          We know how important it is to look your absolute best on your special
          day that's why we choose to use Jane Iredale Skincare Makeup.
          Providing a long lasting flawless finish for all skin types and
          conditions creating the look you desire.
        </p>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Student Makeup</h4>
            <p>&nbsp;-&nbsp;$75</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Trial Makeup</h4>
            <p>&nbsp;-&nbsp;$65</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Special Occasion</h4>
            <p>&nbsp;-&nbsp;$95</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Bridal Makeup</h4>
            <p>&nbsp;-&nbsp;$95</p>
          </div>
        </div> */}
        <h3 className="mt-6 font-bold uppercase">Lash Lift</h3>
        <p>
          Makes natural eyelashes appear longer without eyelash extensions.
          Superior formulation with conditioning and nourishing. Lashes stay
          lifted for 6–8 weeks. No fuss! – No aftercare! Suitable for both short
          and long eyelashes.
        </p>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Lash Lift</h4>
            <p>&nbsp;-&nbsp;$92</p>
          </div>
        </div>
        <div className="mt-2">
          <div className="flex">
            <h4 className="font-bold">Lash Lift</h4>
            <p>&nbsp;including lash tint&nbsp;-&nbsp;$102</p>
          </div>
        </div>
        <p className="mt-4">
          <Link
            to="/information-and-conditions"
            className="font-bold hover:underline"
          >
            Click here to view our Spa Information &amp; Conditions
          </Link>
        </p>
      </div>
    </>
  );
}
