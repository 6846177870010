import { Link } from 'gatsby';
import * as React from 'react';

import { ServicesMenu } from '../services-menu';
import { Service } from '../utils/service';

export function SpaPackages() {
  return (
    <>
      <ServicesMenu service="Spa Journeys" />
      <div className="px-4 mt-6">
        <p>
          Pause, surrender & reconnect. Escape from reality and recharge your
          mind, body & spirit with our signature spa collections. Enjoy on your
          own or share our double treatment room.
        </p>
        <Service title="Indulgence" price={[`$509 `]} time="240 mins">
          <p>
            BODY SCRUB | SPA | BODY WRAP | MASSAGE ROSE QUARTZ FACIAL | EYE
            TREAT | WARM OIL SCALP MASSAGE | PEDICURE | REFRESHMENTS
            <br />
            <br />
            A completely transformational experience. Surrender to a moment of
            calm and total body indulgence. A luxurious combination of
            exfoliation, massage, body wrap, facial and foot care. Your journey
            will begin with our favourite Pure Fiji sugar cane body scrub
            followed by coconut milk bath soak where you’ll enjoy refreshment of
            choice and a grazing board. Calm the mind, relieve stress and
            descend into dream land with a relaxation massage. Whilst you’re
            wrapped in a nourishing cocoon of antioxidants and vitamins, your
            hair and scalp will be treated to a warmed exotic nut oil massage.
            Your customised facial incorporating our rose quartz rollers will
            leave your skin quenched and radiant.
            <br />
            Your experience is complete with a perfecting pedicure*
            <br />
            *Please note we require any shellac, gel or acrylic to be removed
            from the toe nails prior to your appointment
          </p>
        </Service>
        <Service
          title="Decadent Duo"
          price={[`$409 `, <br />, <small>(per couple)</small>]}
          time="120 mins"
        >
          <p>
            SPA | MASSAGE | FACIAL
            <br />
            <br />
            The perfect couple indulgence or just a treat with a good friend. In
            your own private oasis, your treatment begins with a coconut milk
            bath, where you’ll enjoy refreshments of choice, seasonal fruit and
            gourmet chocolates. Then fall into a deep state of relaxation with a
            soothing full body massage and complexion left glowing with
            refreshing facial.
          </p>
        </Service>
        <Service title="Immerse" price="$269" time="120 mins">
          <p>
            FOOT SOAK | MASSAGE | FACIAL | PEDICURE
            <br />
            <br />A full body indulgence to completely unwind, this experience
            combines body massage, facial and foot care. Your spa experience
            will begin with a warm foot soak, to instantly relieve tired feet.
            Continuing to relax the body, your therapist will perform a soothing
            body massage. Skin will feel nourished with an intense hydration
            facial, while feet are treated, with cuticle and nail care finishing
            with your choice of polish colour.
            <br />
            *Please note we require any shellac, gel or acrylic to be removed
            from the toe nails prior to your appointment
          </p>
        </Service>
        <Service title="Invigorate" price="$249" time="90 mins">
          <p>
            BODY SCRUB | BODY WRAP | FACIAL
            <br />
            <br />
            Hydrating your skin and detoxifying your system,
this treatment will leave your mind renewed and body
restored. Your ritual begins with the application of Pure
Fiji sugar cane to gently rid the body of dead skin. After
showering, your body will be treated to a moisturizing honey
masque high in antioxidants and vitamins. Whilst cocooned
enjoy a replenishing facial and scalp massage.
          </p>
        </Service>
        <Service title="Nurture" price="$179" time="75 mins">
          <p>
            FOOT SOAK | FACIAL | LEGS & FEET RITUAL
            <br />
            <br />
            A nurturing treatment for the mum to be or any mama in need
of some down time. You don’t have to be pregnant to indulge
in this treat.
            <br />
            Beginning with a soothing foot soak, then slipping into our
cosy bed to enjoy a repairing and rejuvenating facial including
neck and shoulder massage. Whilst your facial mask is
working it’s magic, we’ll give some much-needed love to
those aching feet. Our Pure Fiji raw sugar cane scrub infused
with nourishing exotic oils will exfoliate dry feet and legs.
A warm Body Butter wrap is then applied to the feet while
your lower legs are massaged with warmed Exotic Oil.
Remaining body butter on feet is then massaged in.
<br/>A hot towel removal of facial mask and hydration products
are applied to complete this treat.
          </p>
        </Service>
        <Service title="Restore" price="$169" time="60 mins">
          <p>
          MASSAGE | FACIAL
            <br />
            <br />
          Recharge and restore body & mind with this perfect pick
me up package. Beginning with a relaxation back, neck and
shoulder massage to instantly soothe. Followed by a nurturing
Facial to nourish and revitalise including tension relief scalp
massage.
          </p>
        </Service>
        <p className="mt-4">
          <Link
            to="/information-and-conditions"
            className="font-bold hover:underline"
          >
            Click here to view our Spa Information &amp; Conditions
          </Link>
        </p>
      </div>
    </>
  );
}
