import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import services from '../data/services.json';
import { SEO } from './seo';

export function ServicesMenu({ service }) {
  const [isActive, toggleIsActive] = useState(false);
  const [isOpen, toggleIsOpen] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/services/') {
      toggleIsActive(true);
    }
  }, []);

  return (
    <>
      <SEO title={service} />
      <div className="leading-tight uppercase">
        <div className="flex items-start justify-between px-4 py-4 leading-none">
          <h1 className="font-serif text-3xl sm:text-4xl">{service}</h1>
          <button
            onClick={() => toggleIsOpen(!isOpen)}
            className="inline-block px-2 py-1 font-sans text-sm font-light uppercase transition-all border border-gray-500 hover:bg-gray-800 hover:border-gray-800 hover:text-white focus:outline-none focus:bg-gray-800 focus:border-gray-800 focus:text-white"
            type="button"
          >
            Menu
          </button>
        </div>
        <ul className={`${isOpen ? '' : 'hidden'}`}>
          {services.map((s) => (
            <li className="border-t border-gray-200 last:border-b hover:border-gray-800">
              <Link
                to={`/services/${s.slug}`}
                className={`${
                  isActive ? 'bg-gray-100 border-gray-100' : ''
                } flex items-center justify-between leading-none p-4 uppercase transition-all w-full hover:bg-gray-800 hover:text-white`}
                activeClassName="bg-gray-100 border-gray-100"
              >
                {s.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

ServicesMenu.propTypes = {
  service: PropTypes.string,
};
