import * as React from 'react';

import { ServicesMenu } from '../services-menu';
import { Service } from '../utils/service';

export function ChristmasPackages() {
  return (
    <>
      <ServicesMenu service="Christmas Packages" />
      <div className="px-4 mt-6">
        <p>Treat your loved on to the most indulgent gift this Christmas.</p>
        <Service title="Season’s Dream" price="$199">
          <p>
            This package brings an instant sense of calm! Your loved one will
            relax in our private room with a soothing foot soak to begin. Skin
            will be left glowing with a customised relaxation facial and the
            ultimate pamper for the feet with a beautifying Pedicure.
          </p>
        </Service>
        <Service title="Season’s Magic" price="$159">
          <p>
            Revitalised and relaxed is how your loved one will feel after this
            beautiful package. Tension will be relieved with a Back, Neck &
            Shoulder Massage, skin replenished with a Hydration Facial and
            stress relieved with a spine- tingling scalp massage.
          </p>
        </Service>
        <Service title="Season’s Treat" price="$109">
          <p>
            A treat for the body this package is sure to have your loved one
            feeling relaxed. Beginning with a warm foot soak followed by
            hydration body massage and to complete a spine-tingling scalp
            massage.
          </p>
        </Service>
      </div>
    </>
  );
}
